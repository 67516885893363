.modal {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;


  @media (max-width: 900px){
    padding: 0;
  }


  .modal-content {
    background-color: white;
    margin: auto;
    position: relative;
    z-index: 400;
    overflow: hidden;
    line-height: 0;
  }

  .modal__button-close {
    position: absolute;
    top: 20px;
    right: 35px;
    z-index: 500;
    cursor: pointer;
    transition: .3s all;
    &:hover {
    svg{
      g {
          fill:rgba(218, 53, 94, 0.9);
        }
      }

    }

  }

  .modal-close {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 300;

  }
}