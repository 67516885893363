.prices {
  padding-top: 60px;

  @media (max-width: 768px) {
    padding: 20px 0 0 0;
  }

  .prices__description {
    max-width: 811px;
    text-align: center;
    margin: 0 auto 55px auto;
    font-size: 16px;
    font-weight: 600;

    @media (max-width: 768px) {
      text-align: left;
      margin: 0 auto 20px auto;
    }

    pre, p, span, div {
      font-size: 16px;
      font-weight: 600;
      white-space: pre-wrap;
    }
  }

  .price__wrapper{
    padding: 0 15px;

    @media (max-width: 768px) {
      padding:0;
    }

  }

  .price__table {
    overflow: hidden;
    border-collapse: collapse;
    width: 100%;
    min-width: 1245px;


    @media (max-width: 768px) {
      min-width: 1050px;
      border-radius: 0;
    }

    thead {
      th {
        //white-space: nowrap;
        background-color: #C8F3E4;
        padding: 17px 20px 17px 20px;
        color: #212121;
        font-size: 12px;
        line-height: 19px;
        font-weight: 400;
        border-left: none;
        border-right: none;
        //text-align: left;
        position: relative;
        text-align: right;

        div {
          margin-bottom: 10px;
          font-size: 12px;
        }

        &:first-child {

          position: relative;
          padding-left: 20px;
          padding-right: 40px;
          text-align: left;
          font-size: 16px;
          white-space: nowrap;
          z-index: 2;

          div{
            font-size: 16px;
            font-weight: 600;
          }

          &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: .3;
            background-color: #B0DFD4;
            z-index: -1;
          }

        }

        //.price__table-date {
        //  position: absolute;
        //  top: calc(50% - 12px);
        //  left: 5px;
        //  width: 1px;
        //  height: 24px;
        //  background-color: rgba(255, 255, 255, 0.33);
        //}

        &:last-child {
          .price__table-date {
            border-right: none;
          }
        }
      }
    }

    tbody {
      tr {


        td {
          text-align: right;

          .price_weekdays {
            &.price__alldays{
              color: #34B288;
            }
          }

          .price_weekend {
            color: rgba(218, 53, 94, 0.6);
            font-weight: 700;
          }

          &:first-child {
            padding: 10px 40px 10px 20px;
            text-align: left;
            max-width: 197px;
            white-space: normal;
            z-index: 2;
            border-bottom: 1px solid #DFE1E6;
            font-size: 15px;
            font-weight: 600;
            color: #444444;
            background-color: #FAFBFC !important;


          }
        }

        &:last-child {
          td {
            //border-bottom: 0;
          }
        }

        &:nth-child(even) {
          td {
            background-color: #FAFBFC;
            &:first-child {
              background-color: white;
            }
          }
        }
      }

      td {
        padding: 20px 20px 20px 20px;
        text-align: left;
        color: #444444;
        font-size: 12px;
        font-weight: 600;
        border-left: none;
        border-right: none;
        background-color: #ffffff;
        white-space: nowrap;
      }
    }
  }

  .price__nav{
    display: flex;
    margin-top: 30px;
    margin-bottom: 66px;

    @media (max-width: 768px) {
      display: block;
      text-align: center;
      margin-bottom: 30px;
    }


    .price__legend{
      flex-basis: calc(50% - 90px);
      flex-grow: 0;
      flex-shrink: 0;

      div{
        color:#212121;
        line-height: 21px;
        font-size: 14px;
        font-weight: 600;
      }

      .price__color--red{
        color: rgba(218, 53, 94, 0.6);
      }

      .price__color--yellow{
        color: #34B288;
      }

    }
    .price__button{
      flex-basis: calc(50% + 90px);
      flex-grow: 0;
      flex-shrink: 0;
      max-width: 180px;
      align-self: center;

      @media (max-width: 768px) {
        margin: 20px auto 0 auto;
      }
    }
  }
}