.double-carousel__carousel {
  flex: 0 0 57%;
  width: 57%;
  max-width: 57%;

  @media (max-width: 1150px) {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
  }

  .double-carousel__single {
    margin-bottom: 6px;
    .single__image {
      background-size: cover;
      background-position: center;
      width: 100%;
      height: 458px;
    }

    .slick-slider{
      .slick-arrow{
        top: calc(50% + 15px);
      }
    }


  }

  .double-carousel__multiple {
    height: 110px;
    padding-top: 1px;
    overflow: hidden;
    padding-left: 5px;

    .slick-track {
      //margin-left: 40px;
      margin-left: 38px;
    }

    .multiple__image {
      position: relative;
      height: 110px;
      background-size: cover;
      background-position: center;
      width: 100%;
      transform: scale(.95);

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, .3);
        transition: .3s all;
      }

      &:hover {
        &:before {
          background-color: rgba(255, 255, 255, 0);
        }
      }
    }
  }
}