.reservation{
  //background-color: #F6F6F6;
  //box-shadow: 0 1px 10px rgba(0, 0, 0, 0.18);
  width: 100%;
  //height: 155px;
  //padding: 22px 44px;
  max-width: 1151px;
  margin: 0 auto;
  //margin-top: -77.5px;
  padding-top: 35px;
  padding-bottom: 50px;
  z-index: 99;
  position: relative;


  @media (max-width: 768px){
    margin-top: 0;
    height: auto;
    //border-radius: 0;
    padding: 20px;
  }

  .reservation__wrapper{
    display: flex;
    align-items: flex-end;

    @media (max-width: 768px){
     flex-wrap: wrap;
    }

    .reservation__item{
      flex-grow: 0;
      flex-shrink: 0;
      margin: 10px;

      .reservation__label {
        display: block;
        font-size: 16px;
        color:#444444;
        margin-bottom: 12px;
      }





      &:first-child{
        flex-basis: calc(25% - 20px);
      }

      &:nth-child(2){
        flex-basis: calc(25% - 20px);
      }

      &:nth-child(3){
        flex-basis: calc(15% - 20px);
      }

      &:nth-child(4){
        flex-basis: calc(15% - 20px);
      }

      &:last-child {
        flex-basis: calc(20% - 20px);

        @media (max-width: 768px){
         //text-align: center;
          margin: 20px auto 10px auto;
        }
      }


      @media (max-width: 768px){
        flex-basis: calc(50% - 20px) !important;
      }

    }
  }
}