// mobile
.modal-service-mobile {
  width: 100%;
  max-width: 100%;

  .slick-track {
    margin-left: 60px;
  }

  .modal-service-mobile__descr {
    padding: 15px;

    p, h1, h2, h3, h4, h5, h6 {
      font-size: 14px;
      color: #444444;
      line-height: 26px;
      font-weight: 600;
      margin-bottom: 30px;
    }
  }

  .modal-service-mobile__image {
    height: 250px;
    background-size: cover;
    background-position: center;
    transform: scale(0.98);
  }

  .modal-service-mobile__pdf {
    padding-left: 15px;
    font-size: 16px;
    color: #444444;
    font-weight: 600;

    a {
      color: #34B288;
      text-decoration: none;
      font-weight: 600;
      font-size: 18px;
    }
  }
}

//desktop
.modal-services {
  width: 1366px;
  height: 585px;
  max-width: 100%;

  .modal-services__wrapper {
    display: flex;
    width: 100%;
  }

  .modal-services__data {
    flex: 0 0 43%;
    background-color: white;
    padding: 20px 0 20px 39px;
    display: flex;
    align-items: center;
    //justify-content: center;

    @media (max-width: 1150px) {
      flex: 0 0 50%;
    }

    .modal-services__text {
      padding-top: 25px;

      padding-right: 80px;

      h1 {
        margin-bottom: 32px;
        font-size: 34px;
        color: #212121;
      }

      .modal-services__description {


        a {
          font-size: 16px;
          color: #34B288;

          &:visited {
            color: #34B288;

          }
        }

        strong {
          a {
            font-weight: 700;
          }
        }

        p {
          font-size: 16px;
          color: #444444;
          line-height: 26px;
          font-weight: 600;
          margin-bottom: 30px;
        }
      }
    }

    .modal-service__pdf {
      font-size: 16px;
      color: #444444;
      font-weight: 600;

      a {
        color: #34B288;
        text-decoration: none;
        font-weight: 600;
        font-size: 18px;
      }
    }
  }

}