.body{
  position: relative;

  //.body__background{
  //  position: absolute;
  //  top:0;
  //  left: 0;
  //  //background-image: url("/images/background/background.png");
  //  width: 100%;
  //  height: 100%;
  //  background-repeat: repeat;
  //  z-index: -1;
  //  opacity: .1;
  //
  //  @media (max-width: 768px){
  //    background-image: none;
  //  }
  //}

  .body__wrapper{
    position: relative;
    z-index: 10;
  }




}