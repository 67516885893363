.select{
  width: 100%;
  position: relative;

  select{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    padding-left: 15px;
    background-color: white;
    border: 1px solid #DCDCDC;
    color: #888888;
    font-size: 14px;
    text-align: left;
    height: 40px;
    border-radius: 3px;
  }


  .select__icon{
    position: absolute;
    right: 12px;
    top: calc(50% - 3px);
    pointer-events:none;
  }
}