.room {
  padding: 60px 0;

  @media (max-width: 768px) {
    padding: 0;
  }

  .room__description {
    max-width: 811px;
    text-align: center;
    margin: 0 auto 55px auto;
    font-size: 16px;
    font-weight: 600;

    @media (max-width: 768px) {
      text-align: left;
      margin: 0 auto 20px auto;
    }

    pre, p, span, div {
      font-size: 16px;
      font-weight: 600;
      white-space: pre-wrap;
    }
  }

  .room__wrapper {

    @media (max-width: 768px) {
      padding-bottom: 40px;
    }

    .room__carousel {
      max-width: 1200px;
      margin:  0 auto;

      .slick-track {
        @media (max-width: 768px) {
          margin-left: 35px;
        }
      }

      .slick-slider {
        .slick-prev {
          left: 68px;
          z-index: 1;
        }
        .slick-next {
          right: 68px;
        }
      }

      .slick-slide {
        transition: .3s all;
        padding: 10px;
      }

      .slick-dots {
        bottom: -50px;

        @media (max-width: 768px) {
          bottom: -40px;
        }
      }

      .room__item {
        position: relative;
        height: 560px;
        overflow: hidden;

        @media (max-width: 1400px) {
          height: 600px;
        }

        @media (max-width: 1024px) {
          height: 450px;
        }

        .room__text {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          min-height: 200px;
          //height: 100%;
          z-index: 10;
          padding: 25px;

          //@media (max-width: 1024px) {
          //  padding: 50px 50px 50px 50px;
          //}

          h3 {
            font-size: 26px;
            font-weight: 600;
            color: white;
            line-height: 35px;
            margin-bottom: 17px;
            text-align: center;
          }

          .room__descr {
            display: block;
            color: white;
            line-height: 24px;
            font-size: 16px;
            max-width: 460px;
            margin: 0 auto;
            text-align: center;

            pre, p, span, div {
              font-size: 18px;
              white-space: pre-wrap;
            }

            @media (max-width: 1024px) {
              font-size: 14px;
              max-width: 100%;
            }
          }
        }

        .room__image {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-size: cover;
          background-position: center;

          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;

            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            transition: .3s all;
          }

        }
      }

    }
  }
}