.services {
  margin-bottom: 130px;

  .services__wrapper {
    flex-wrap: wrap;
    padding: 0 25px;
    display: grid;
    grid-template-columns: repeat(3, 120px);
    justify-content: space-between;
    grid-column-gap: 130px;
    grid-row-gap: 60px;
    max-width: 900px;
    margin: 40px auto 0 auto;

    img{
      max-width: 120px;
    }

    @media (max-width: 950px) {
      grid-template-columns: repeat(2,1fr);
      grid-gap: 60px;

      img{
        max-width: 76px;
      }
    }


    .services__item {
      text-decoration: none;
      position: relative;
      text-align: center;

      .services__image {
        object-fit: cover;

        width: 100%;
        transition: .3s all;

      }

      .services__text {


        h2 {
          color: #172B4D;
          font-weight: 400;
          font-size: 24px;
          line-height: 33px;
          margin: 7px 0 11px 0;

          @media (max-width: 900px) {
            font-size: 16px;
            line-height: 21px;
          }
        }
      }
    }
  }
}