
.button {
  button {
    -moz-appearance: none;
    appearance: none;
  }

  &.button-outline {
    button, a {
      background-color: rgba(0, 0, 0, 0);
      border: 1px solid #CB6354;
      color: #CB6354;
    }

    &:hover{
      button, a {

        color: white;
      }
    }
  }


  button, a {
    min-width: 170px;
    width: 100%;
    height: 40px;
    background-color: #CB6354;
    border-radius: 6px;
    padding: 0 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
    transition: .3s all;

    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;

    @media (max-width: 480px) {
        height: 55px;
      min-width: 210px;
      border-radius: 30px;
      font-size: 18px;
    }
  }

  //&:hover{
  //  button, a {
  //    background-image: linear-gradient(#258061, #258061);
  //    border-color: #258061;
  //  }
  //}
}