

.datepicker{
  .react-datepicker-wrapper{
    .react-datepicker__input-container{
        width: 100% !important;
    }
  }
}
.react-datepicker__input-container{
  width: 100% !important;
}

// style for datepickers //
.datepicker {
  position: relative;

  .react-datepicker-wrapper{
    width: 100%;
  }

  svg {
    position: absolute;
    top: 11px;
    right: 10px;
    pointer-events:none;
  }

  input, .react-datepicker__input-container > button{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    //border: none;
    padding-left: 15px;
    width: 100%;
    //width: 270px;
    height: 40px;
    background-color: white;
    border: 1px solid #DCDCDC;
    color: #888888;
    font-size: 14px;
    text-align: left;
    border-radius: 3px;

    @media (max-width: 1124px) {
      width: 100%;
    }

  }
}