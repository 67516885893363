.track-vertical {
  background-color: white;
  width: 100%;
  left: 0px;
  bottom: 0px;
}

.new__back {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  z-index: 90;

  &.hide {
    display: none;
  }

  &.show {
    display: block;
  }
}

.new {
  position: fixed;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
  max-width: 100%;
  max-height: 100%;
  height: 400px;
  background-color: white;
  z-index: 99;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  transition: .5s all;

  &.hide {
    transform: translate(200%, 0);
    opacity: 0;

  }
  &.show {
    opacity: 1;
  }

  .close__new {
    cursor: pointer;
    position: absolute;
    z-index: 10;
    right: 20px;
    top: 20px;

    &:hover {
      g {
        fill: red;
      }
    }
  }
}

.new__wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  .new__left {
    flex: 1 1 45%;
  }

  .new__image {
    background-position: center;
    background-size: cover;
    height: 100%;
    width: 100%;
  }

  .new__right {
    flex: 1 1 65%;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-right: 95px;

    @media (max-width: 768px) {
      padding-top: 20px;
      padding-bottom: 20px;
      padding-right: 0;
    }

    .new__inner {
      padding: 0 20px 0 30px;
      font-size: 18px;

      @media (max-width: 768px) {
        padding: 0 20px;
      }
      h1 {
        margin-bottom: 20px;
      }

      p, span, div, pre {
        font-size: 18px;
      }
    }
  }
}

.new__toggle {
  position: fixed;
  bottom: 15px;
  //top: calc(50% - 40px);
  right: 15px;
  z-index: 89;
  width: 300px;
  height: 84px;
  background-color: #34B288;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: .3s all;
  box-shadow: 4px -4px 38px -1px rgba(0, 0, 0, 0.2);
  //box-shadow: 0 1px 10px rgba(0, 1px, 4px, 0.3);

  &.show {
    display: flex;
  }

  &.hide {
    display: none;
  }

  @media (max-width: 768px) {
    height: 54px;
    bottom: 10px;
    top: auto;
  }

  .toggle__text {
    padding: 0 20px;
    white-space: nowrap;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    color: white;
    font-weight: 600;
    font-size: 16px;
  }
}