
.location {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-bottom: 60px;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }

  .location__left{
    padding: 35px 0;
    max-width: 700px;
  }

  .location__right {
    .location__img {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: right;

      @media (max-width: 900px) {
        height: 400px;
      }

      @media (max-width: 480px) {
        height: 285px;
      }

    }
  }
}

