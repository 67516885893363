.footer {
  position: relative;
  height: 580px;
  overflow: hidden;
  padding: 25px;

  //@media (max-width: 900px) {
  //  height: 500px;
  //}

  &__phone {
    line-height: 24px;
    font-size: 18px;
    color: white;
    text-decoration: none;
    margin-bottom: 8px;
    display: block;
  }

  &__block {
    margin-bottom: 32px;
  }

  &__title {
    font-size: 21px;
    margin-bottom: 17px;
  }

  &__address {
    color: white;
    font-size: 16px;
  }

  &__icons {
    display: flex;
    align-items: center;
    margin-top: 32px;
  }

  &__social {
    display: block;
    margin-right: 20px;
  }

  &__email {
    color: white;
    font-size: 14px;

    a {

      text-decoration: none;
    }
  }

  span, div, a {
    color: white;
  }

  .footer__wrapper {
    position: relative;
    z-index: 4;
    padding-top: 40px;
    height: 100%;


    .footer__column {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .footer__blocks {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 20px;

      @media (max-width: 900px) {
        grid-template-columns: 1fr;
      }

      .footer__right {
        //padding-bottom: 20px;
      }

      .footer__left {

      }
    }

    .footer__bottom {
      border-top: 1px solid rgba(255, 255, 255, 0.33);
      padding-top: 35px;
      padding-bottom: 10px;
      grid-template-columns: 1fr 1fr;

      display: grid;
      grid-row-gap: 20px;

      @media (max-width: 900px) {
        grid-template-columns: 1fr;
      }

      .footer__info {
        color: white;
        font-size: 13px;
        font-weight: 400;
        line-height: 19px;

        //&:last-child{
        //  margin-left: auto;
        //  text-align: right;
        //
        //  @media (max-width: 900px) {
        //    margin-left: 0;
        //    text-align: left;
        //  }
        //}


        a {
          text-decoration: none;
          color: white;
          font-size: 14px;
          font-weight: 600;
          line-height: 19px;
        }
      }
    }
  }

  .footer__background {
    position: absolute;
    background-size: cover;
    background-position: center bottom;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    //filter: blur(5px);
  }

  .footer__shadow {
    position: absolute;
    z-index: 2;
    height: 100%;

    &.left {
      background: linear-gradient(90.18deg, #1B2118 0.13%, rgba(27, 33, 24, 0) 99.81%);
      opacity: 0.5;
      width: 40%;
      left: 0;
      top: 0;
    }

    &.right {
      height: 100%;
      background: linear-gradient(90.18deg, #1B2118 0.13%, rgba(27, 33, 24, 0) 99.81%);
      transform: rotate(-180deg);
      width: 40%;
      top: 0;
      right: 0;
    }

    &.top {
      background: linear-gradient(179deg, #1B2118 0.13%, rgba(27, 33, 24, 0) 99.81%);
      opacity: 0.6;
      //transform: rotate(90deg);
      height: 200px;
      width: 100%;
      top: 0;
      left: 0;
    }
  }

}